<template>
    <card>
        <body-card style="margin: 0;border: none;">
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('teaGarden.task_assign') }} {{ $t('globalTrans.details') }}</h4>
            </template>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <div class="p-3">
                        <b-row>
                            <b-col sm="12">
                                <b-table-simple small resonsive borderless>
                                    <b-tr>
                                        <b-th width="25%">{{ $t('teaGardenService.tea_garden_name') }}</b-th>
                                        <b-th width="2%">:</b-th>
                                        <b-td>
                                            {{ getGardenName(formData.garden_id) }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('teaGarden.task_name') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ getTaskName(formData.task_id) }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('teaGardenConfig.description') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ currentLocale === 'en' ? formData.description_en : formData.description_bn }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('globalTrans.start_date') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ formData.start_date | dateFormat }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('globalTrans.end_date') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ formData.end_date | dateFormat }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('teaGarden.working_days') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ $n(formData.working_days) }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr v-if="taskUnitId">
                                        <b-th>{{ $t('teaGarden.target') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ $n(formData.target) }} {{ getUnitName(taskUnitId) }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr v-if="taskUnitId">
                                        <b-th>{{ $t('teaGarden.total_target') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ $n(formData.working_day_target) }} {{ getUnitName(taskUnitId) }}
                                        </b-td>
                                    </b-tr>
                                </b-table-simple>
                                <body-card>
                                    <!-- search section start -->
                                    <template v-slot:headerTitle>
                                        <h4 class="card-title">{{ $t('teaGarden.task_assign_achievement_list') + ' ' + $t('globalTrans.search') }}</h4>
                                    </template>
                                    <template v-slot:body>
                                        <b-row>
                                            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                <b-form-group
                                                  class="row"
                                                  label-for="start_date">
                                                    <template v-slot:label>
                                                        {{ $t('teaGarden.achievement_date') }}
                                                    </template>
                                                    <date-picker
                                                      id="achievement_date"
                                                      v-model="search.achievement_date"
                                                      class="form-control"
                                                      :placeholder="$t('globalTrans.select_date')"
                                                      :locale="currentLocale"
                                                    >
                                                    </date-picker>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="4">
                                                <b-button size="sm" variant="primary" class="mt-20" @click.prevent="searchData">
                                                    <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </template>
                                    <!-- search section end -->
                                </body-card>
                                <body-card>
                                    <!-- table section start -->
                                    <template v-slot:headerTitle>
                                        <h4 class="card-title">{{ $t('teaGarden.task_assign_achievement_list') }}</h4>
                                    </template>
                                    <template v-slot:headerAction>
                                    </template>
                                    <template v-slot:body>
                                        <b-overlay :show="loadingState">
                                            <b-row>
                                                <b-col sm="12">
                                                    <div class="quick-filter-wrapper">
                                                        <div class="quick-filter-left">
                                                            <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                                                                <b-dropdown-form>
                                                                    <div
                                                                      class="form-group"
                                                                      v-for="(field, index) in labelData"
                                                                      :key="index"
                                                                    >
                                                                        <b-form-checkbox
                                                                          :id="'checkbox-' + field.labels"
                                                                          v-model="field.show"
                                                                          :name="'checkbox-' + field.labels"
                                                                          value=1
                                                                          unchecked-value=0
                                                                        >
                                                                            {{ $t(field.labels)  }}
                                                                        </b-form-checkbox>
                                                                    </div>
                                                                </b-dropdown-form>
                                                            </b-dropdown>
                                                        </div>
                                                        <div class="quick-filter-right">
                                                            <b-form-group
                                                              :label="$t('menu.perpage')"
                                                              label-for="per-page-select"
                                                            >
                                                                <b-form-select
                                                                  id="per-page-select"
                                                                  v-model="search.limit"
                                                                  :options="pageOptions"
                                                                  size="sm"
                                                                ></b-form-select>
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col md="12" class="table-responsive">
                                                    <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                                                        <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                                                            <template v-slot:cell(serial)="data">
                                                                {{ $n(data.item.serial + pagination.slOffset) }}
                                                            </template>
                                                            <template v-slot:cell(total_achievement)="data">
                                                                <span>{{ $n(data.item.total_achievement) }}</span>
                                                            </template>
                                                            <template v-slot:cell(total_diff)="data">
                                                                <span>{{ $n(data.item.total_diff) }}</span>
                                                            </template>
                                                            <template v-slot:cell(achievement_date)="data">
                                                                <span>{{ data.item.achievement_date | dateFormat }}</span>
                                                            </template>
                                                            <template v-slot:cell(action)="data">
                                                                <router-link variant=" iq-bg-success mr-1" size="sm" class="moc-edit-btn moc-action-btn action-btn edit" :to="`/tea-garden-service/garden/task-assign-achievement-individual/${data.item.task_assign_id}?achievement_id=${data.item.id}`" :title="$t('globalTrans.edit')">
                                                                    <i class="ri-pencil-fill"></i>
                                                                </router-link>
                                                                <router-link class="moc-action-btn btn-success" :to="`/tea-garden-service/garden/task-assign-achievement-individual-details/${data.item.task_assign_id}?achievement_id=${data.item.id}`" :title="$t('teaGarden.achievement')">
                                                                    <i class="ri-eye-line"></i>
                                                                </router-link>
                                                            </template>
                                                        </b-table>
                                                    </slot>
                                                    <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                                                        <b-pagination
                                                          v-model="pagination.currentPage"
                                                          :perPage="search.limit"
                                                          :total-rows="pagination.totalRows"
                                                          @input="searchData"
                                                        />
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-overlay>
                                    </template>
                                    <!-- table section end -->
                                </body-card>
                            </b-col>
                        </b-row>
                    </div>
                </b-overlay>
            </template>
        </body-card>
    </card>
</template>
<script>
    import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
    import { taskAssignAchievementList } from '../../api/routes'
    import ModalBaseMasterList from '@/mixins/list'
    export default {
        mixins: [ModalBaseMasterList],
        name: 'Details',
        data () {
            return {
                teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
                formData: {
                    id: 0,
                    total_achievement: '',
                    total_diff: '',
                    workers: [],
                    achievement: {
                        achievement_date: '',
                        workers: []
                    }
                },
                taskUnitId: 0,
                task: {
                    is_individual: 0
                },
                workerLoading: false,
                sortBy: '',
                sortDirection: 'desc',
                sortDesc: true,
                search: {
                    achievement_date: '',
                    limit: 20
                },
                labelData: [
                    { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '7%' } },
                    { labels: 'teaGarden.achievement_date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: 'auto' } },
                    { labels: 'teaGarden.total_achievement', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: 'auto' } },
                    { labels: 'teaGarden.total_diff', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: 'auto' } },
                    { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 5, thStyle: { width: 'auto' } }
                ],
                actions: {
                    edit: true,
                    toogle: true,
                    delete: false
                }
            }
        },
        created () {
            this.loadData()
        },
        computed: {
            id () {
                return this.$route.params.id
            },
            currentLocale () {
                return this.$i18n.locale
            },
            loading () {
                return this.$store.state.commonObj.loading
            },
            gdnTaskList () {
                return this.$store.state.TeaGardenService.commonObj.gdnTaskList
            },
            designationList: function () {
                return this.$store.state.TeaGardenService.commonObj.masterDesignationList
            },
            genderList: function () {
                return this.$store.state.TeaGardenService.commonObj.gender
            },
            unitList () {
                return this.$store.state.TeaGardenService.commonObj.unitList
            },
            columns () {
                const labelData = this.labelData
                const labels = labelData.map((item, index) => {
                    return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
                })
                let keys = []
                keys = [
                    { key: 'serial' },
                    { key: 'achievement_date' },
                    { key: 'total_achievement' },
                    { key: 'total_diff' },
                    { key: 'action' }
                ]
                return labels.map((item, index) => {
                    return Object.assign(item, keys[index])
                })
            },
            pageOptions () {
                return this.$store.state.commonObj.pageOptions
            },
            customWorkerTypeList: function () {
                return this.$store.state.TeaGardenService.commonObj.workerType
            }
        },
        watch: {
            'search.limit': function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.loadData()
                }
            }
        },
        methods: {
            searchData () {
                this.loadData()
            },
            back () {
                this.$router.go(-1)
            },
            async loadData () {
                // this.loading = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const result = await RestApi.getData(teaGardenServiceBaseUrl, taskAssignAchievementList + '/' + this.id, this.search)
                if (result.success) {
                    this.formData = result.model
                    const obj = this.gdnTaskList.find(item => item.value === parseInt(this.formData.task_id))
                    this.task = obj
                    if (obj !== undefined && obj.unit_id) {
                        this.taskUnitId = obj.unit_id
                    }
                    const listData = result.data.data.map((item, index) => {
                        return Object.assign({}, item, { serial: index })
                    })
                    this.$store.dispatch('setList', listData)
                    this.paginationData(result.data, this.search.limit)
                } else {
                    this.$store.dispatch('setList', [])
                    this.paginationData([])
                }
                // this.loading = false
                this.$store.dispatch('mutateCommonProperties', { loading: false })
            },
            getGardenName (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getTaskName (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.gdnTaskList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getWorkerType (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.workerType.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getDesignation (id) {
                const obj = this.designationList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getGender (id) {
                const obj = this.genderList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getUnitName (id) {
                const obj = this.unitList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            }
        }
    }
</script>
<style scoped>
    .tag {
        font-weight: 600;
        border-left: 4px solid #1c4261;
        padding-left: 6px;
    }
    .download-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 2px 4px;
    }
    .yes-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
    }
    .no-btn {
        color: red;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
        margin-right: 33px !important;
    }
    .tag {
        font-weight: 600;
        border-left: 4px solid #1c4261;
        padding-left: 6px;
    }
    .download-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 2px 4px;
    }
    .yes-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
    }
    .no-btn {
        color: red;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
        margin-right: 33px !important;
    }

    .stepper-wrapper {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .stepper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
    .stepper-item::before {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: -50%;
        z-index: 2;
    }

    .stepper-item::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 2;
    }

    .stepper-item .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ccc;
        margin-bottom: 6px;
        color: white;
    }

    .stepper-item.active {
        font-weight: bold;
    }

    .stepper-item.completed .step-counter {
        background-color: #4bb543;
        color:white;
    }

    .stepper-item.completed::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #4bb543;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 3;
    }

    .stepper-item:first-child::before {
        content: none;
    }
    .stepper-item:last-child::after {
        content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
    .tagTwo {
        position: relative;
        display: inline-block;
        border-radius: 6px;
        clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
        background: hsl(250deg, 100%, 40%);
        padding: 5px 25px;
        font-weight: 600;
        font-size: 12px;
        color: #FFF;
        transition: clip-path 500ms;
    }
    .tagTwo:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: hsl(252deg, 100%, 60%);
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 6px 0;
        transition: transform 500ms;
    }
</style>
